import { Form, useSubmit } from "@remix-run/react";
import { useTranslation } from "react-i18next";
import CommentIcon from "~/assets/icons/commentIcon";
import SelectRAC, { SelectItem } from "~/components/Select";

export const LANGUAGE_FR = "fr";
export const LANGUAGE_EN = "en";

export const LocaleEnum = Object.freeze({
  fr: "Français (FR)",
  en: "English (EN)",
});

const options = [
  {
    id: LANGUAGE_FR,
    textValue: LocaleEnum.fr,
    key: LANGUAGE_FR,
  },
  {
    id: LANGUAGE_EN,
    textValue: LocaleEnum.en,
    key: LANGUAGE_EN,
  },
];

/**
 * Language selector component used in the app to select the language of interfaces
 * @param props object containing
 * locale: the selected language
 */
export default ({ locale }: { locale: string }) => {
  const submit = useSubmit();
  const { t } = useTranslation();

  return (
    <Form>
      <SelectRAC
        startIcon={<CommentIcon className="mr-2" />}
        onSelectionChange={(locale: any) => {
          submit({ lng: locale }, { method: "get", preventScrollReset: true });
        }}
        selectedKey={locale}
        variant="information"
        aria-label={t("LOGIN_LANGUAGE_SELECTOR_LABEL")}
      >
        {options.map(({ id, textValue, key }) => (
          <SelectItem key={key} id={id} variant="information">
            {textValue}
          </SelectItem>
        ))}
      </SelectRAC>
    </Form>
  );
};
